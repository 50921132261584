import { paidPlansPropsMap } from '../../PaidPlans/paidPlansPropsMap';
import { getChallengeEligiblePaidPlans } from '../../../components/ChallengesPage/Widget/components/Pricing/helpers/getChallengeEligiblePaidPlans';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengeId } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';

/**
 * we need to abort join flow after login in case when user already has eligible paid plan
 */
export async function isNeedToAbortJoinFlowAfterLogin(
  flowAPI: ControllerFlowAPI,
): Promise<boolean> {
  const challengeId = await getChallengeId(flowAPI);
  const paidPlansProvider = await paidPlansPropsMap(flowAPI);
  await paidPlansProvider.updatePromise;

  const challengeEligiblePaidPlan = getChallengeEligiblePaidPlans(
    paidPlansProvider.eligiblePlans,
    paidPlansProvider.userPaidPlans,
    challengeId,
  );

  return Boolean(challengeEligiblePaidPlan.length);
}
